import * as React from "react";

import Layout from "../components/layout";
import { withPrefix } from "gatsby";

import Parallax0 from "../images/overrun/parallax-0.jpg";
import Parallax1 from "../images/overrun/parallax-1.png";
import Parallax2 from "../images/overrun/parallax-2.png";
import Parallax3 from "../images/overrun/parallax-3.png";
import Parallax4 from "../images/overrun/parallax-4.png";
import Parallax5 from "../images/overrun/parallax-5.png";
import OverrunText from "../images/overrun/overrun-text.png";

import Screenshot1 from "../images/overrun/screenshots/screenshot-1.jpg";
import Screenshot2 from "../images/overrun/screenshots/screenshot-2.jpg";
import Screenshot3 from "../images/overrun/screenshots/screenshot-3.jpg";
import Screenshot4 from "../images/overrun/screenshots/screenshot-4.jpg";
import Screenshot5 from "../images/overrun/screenshots/screenshot-5.jpg";
import Screenshot6 from "../images/overrun/screenshots/screenshot-6.jpg";

import Dot from "../images/overrun/roadmap-dot.png";
import DotComplete from "../images/overrun/roadmap-dot-complete.png";
import Car from "../images/overrun/car.png";

import { Helmet } from "react-helmet";

import { Carousel } from "react-bootstrap";
import GooglePlay from "../images/google-play-badge.png";
import AppStore from "../images/app-store-badge.png";

class OverrunPage extends React.Component {
  componentDidMount() {
    dispatchEvent(new Event("load"));
  }

  render() {
    return (
      <Layout>
        <div className="overrun-navbar-separator" id="overrun" />
        <div className="overrun-content">
          <div className="overrun-border z-index-0" />
          <div className="container">
            <img
              src={OverrunText}
              className="overrun-branding-text element josh-js"
              data-josh-anim-name="lightSpeedInRight"
              data-josh-duration="1000ms"
              data-josh-anim-delay="2s"
            />
            <p
              className="overrun-tagline element josh-js"
              data-josh-anim-name="fadeIn"
              data-josh-duration="1000ms"
              data-josh-anim-delay="2s"
            >
              Overrun is a post-apocalyptic zombie shooting survival game that
              puts you in charge of a group of survivors. Build a strong base,
              grow your team, and equip your them with brutal weapons to survive
              the zombie apocalypse.
            </p>
            <div className="row">
              <div
                className="col-lg-8 element josh-js"
                data-josh-anim-name="fadeInLeft"
                data-josh-anim-delay="2s"
                data-josh-duration="1000ms"
              >
                <Carousel
                  controls={true}
                  indicators={false}
                  className="overrun-carousel"
                >
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Screenshot1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Screenshot2}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Screenshot3}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Screenshot4}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Screenshot5}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={Screenshot6}
                      alt="First slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div
                className="col-lg-4 element josh-js"
                data-josh-anim-name="fadeInRight"
                data-josh-anim-delay="2s"
                data-josh-duration="1000ms"
              >
                <h2 className="text-center mt-4 mt-lg-0">DOWNLOAD</h2>
                <div className="row text-center my-4">
                  <div className="col">
                    <a href="https://play.google.com/store/apps/details?id=wildfiregames.zwg">
                      <img
                        className="store-badge"
                        src={GooglePlay}
                        alt={"Download on Google Play"}
                      />
                    </a>
                  </div>
                  <div className="col">
                    <a href="https://apps.apple.com/app/id1534241963">
                      <img
                        className="store-badge"
                        src={AppStore}
                        alt={"Download on the Apple App Store"}
                      />
                    </a>
                  </div>
                </div>
                <p>
                  Overrun throws you into a post apocalypse zombie world where
                  your survival is on the line. Build a defense against the
                  zombies, equip your survivors with devastating weapons, and
                  fight the horde of zombies to progress to each new map
                  location.
                </p>
                <p>
                  Unlock new weapons, turrets, and barricades to hold back the
                  zombies as you fight for your survival. Recruit new survivors
                  to your team and level them up until they are the ultimate
                  zombie slayers!
                </p>
              </div>
            </div>
          </div>

          <div className="roadmap-container position-relative">
            <div className="overrun-border position-absolute" />
            <div className="container">
              <div className="roadmap-road">
                <h1
                  className="text-center element josh-js"
                  data-josh-anim-name="bounceIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  Development Road Map
                </h1>
                <p
                  className="overrun-tagline element josh-js"
                  data-josh-anim-name="bounceIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  Below is what we plan on bringing to Overrun in the coming
                  months. This is subject to change but generally is what we
                  intend on doing.
                </p>
                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">INITIAL RELEASE</h3>
                    <p>
                      The first public release of the game. One level, infinite
                      waves of zombies, unlockables up to wave 30.
                    </p>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 1.0 - 1.50</h3>
                    <p>
                      Many bug fixes, performance, and quality of life
                      improvements based on the feedback of the community 🙂
                    </p>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 1.50 - FEATURES</h3>
                    <ul>
                      <li>
                        Survivors no longer die, downed survivors now return at
                        the end of the wave.
                      </li>
                      <li>Added a button to refresh shop inventory.</li>
                      <li>
                        Added cloud saves to sync progress across devices.
                      </li>
                      <li>Added a fast forward time button.</li>
                      <li>
                        Added 12 new achievements (with more to come in future).
                      </li>
                      <li>
                        Added leaderboards for the most kills, Z Bucks, and
                        highest wave achieved.
                      </li>
                      <li>Added more ways to get FREE Z Bucks.</li>
                      <li>
                        Combined build mode and demolish mode in to one
                        'construction mode'.
                      </li>
                      <li>
                        Fixed lots more minor bugs in the game and tweaked the
                        balance of the game.
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 1.60 - PERFORMANCE</h3>
                    <p>
                      Massive performance improvements and added the ability to
                      rename survivors
                    </p>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 1.70 - MAPS</h3>
                    <ul>
                      <li>
                        Added a mew map system and a new map 'Construction
                        Site'.
                      </li>
                      <li>
                        Structures now go red to show how damaged they are.
                      </li>
                      <li>
                        Added a repair kit item to repair structures in-game.
                      </li>
                      <li>Added repair all button in the construction menu.</li>
                      <li>
                        Added brightness slider in settings for night time
                        visibility.
                      </li>
                      <li>Balanced stats of guns, structures and items.</li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 1.80 - TAKE CONTROL</h3>
                    <ul>
                      <li>
                        Added a new twin-stick mode for controlling individual
                        survivors!
                      </li>
                      <li>Improved build mode controls.</li>
                      <li>Major pathfinding performance improvements.</li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 1.90 - POWER PLANT</h3>
                    <ul>
                      <li>Added a new 'Power Plant' map.</li>
                      <li>
                        Added a new radiation zombie that slowly damages all
                        survivors in a radius.
                      </li>
                      <li>
                        Added a new demolisher zombie that tries to blow up your
                        defenses.
                      </li>
                      <li>
                        Added a stun grenade to stop zombies in their tracks
                        temporarily.
                      </li>
                      <li>
                        The last wave on a map now is a boss wave with an extra
                        50% difficulty boost.
                      </li>
                      <li>
                        Added an oil barrel structure that explodes and spreads
                        fire like a molotov when it is destroyed.
                      </li>
                      <li>
                        Minor bug fixes, performance improvements, tweaks and
                        balancing.
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 2.0 - LANGUAGE SUPPORT</h3>
                    <ul>
                      <li>
                        Add support for multiple languages starting with
                        Portuguese, Spanish, French, and German.
                      </li>
                      <li>
                        Overhaul the tutorial to introduce players to the new
                        features added since version 1.0.
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 2.10 - ELECTRICITY</h3>
                    <ul>
                      <li>
                        Add electricity system and electrical structures and
                        traps.
                      </li>
                      <li>
                        Add 2 new maps, the 'Trailer Park' and 'Quarantine
                        Zone'.
                      </li>
                      <li>Add new zombie types for each new map.</li>
                      <li>
                        Add a jump button in twin-stick mode to allow players to
                        jump over obstacles.
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 2.20 - EXTRAS</h3>
                    <ul>
                      <li>Add a new 'The Suburbs' map.</li>
                      <li>
                        Add the legendary 'Ray Gun' weapon that deals area
                        damage as well as a large amount of damage to the zombie
                        it hits.
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 2.30 - FINAL MAPS</h3>
                    <ul>
                      <li>
                        Add the final 2 maps 'Hospital' and 'Military Base'.
                      </li>
                      <li>
                        Add raider waves where enemy survivors attack the camp.
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 2.40 - POWER UPS</h3>
                    <ul>
                      <li>
                        Add power ups that are dropped by zombies when they die
                        and can be collected by survivors.
                      </li>
                      <li>
                        Change the way structures are demolished to allow them
                        to be easily rebuilt and repaired during a wave.
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 2.50 - STORY</h3>
                    <ul>
                      <li>Implement the story mode, cutscenes and all.</li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={DotComplete}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 2.60 - PERFORMANCE</h3>
                    <ul>
                      <li>Massive performance improvements to bullets.</li>
                      <li>Bug fixes.</li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item overrun-roadmap-highlight element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img
                      src={Car}
                      className="roadmap-dot element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                    />
                    <div className="roadmap-line" />
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 2.70 - CROSS PLATFORM</h3>
                    <ul>
                      <li>Add controller support.</li>
                      <li>Overhaul the user interface for controller use.</li>
                      <li>Begin work on the PC / Mac port of the game.</li>
                    </ul>
                  </div>
                </div>

                <div
                  className="row justify-content-center roadmap-item element josh-js"
                  data-josh-anim-name="fadeIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms"
                >
                  <div className="col-auto overflow-hidden">
                    <img src={Dot} className="roadmap-dot" />
                    {/*<div className="roadmap-line" />*/}
                  </div>
                  <div className="col col-md-8 col-lg-10 py-3">
                    <h3 className="d-inline">Version 3+ - FUTURE IDEAS</h3>
                    <ul>
                      <li>
                        Add skills to some of the survivors that give them
                        active and passive abilities.
                      </li>
                      <li>
                        Add a challenge system to keep 3 active challenges that
                        when completed give sweet rewards.
                      </li>
                    </ul>
                  </div>
                </div>

                <p className="overrun-tagline">
                  We welcome all feedback and suggestions for future updates 😎
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="overrun-border" />
        <div className="parallax-container" id="parallaxContainer">
          <div className="parallax" id="parallax">
            <img src={Parallax0} alt={"Parallax layer 0"} id={"parallax0"} />
            <img src={Parallax1} alt={"Parallax layer 1"} id={"parallax1"} />
            <img src={Parallax2} alt={"Parallax layer 2"} id={"parallax2"} />
            <img src={Parallax3} alt={"Parallax layer 3"} id={"parallax3"} />
            <img src={Parallax4} alt={"Parallax layer 4"} id={"parallax4"} />
            <img src={Parallax5} alt={"Parallax layer 5"} id={"parallax5"} />
          </div>
        </div>
        <div className="overrun-border" />
      </Layout>
    );
  }
}

export default OverrunPage;
